import React from 'react'
import PropTypes from 'prop-types'
import content from './content'
import { IrisImg } from '../../Shared'

const ContentPanel = ({ file, industry, order, width }) => {
  const bgClass = order % 2 === 0 ? 'blue-bg' : 'white-bg'
  const panelContent = content[industry][order]
  const sizeClass = width < 745 ? 'small' : width > 992 ? '' : 'medium'

  const theImage = (
    <IrisImg
      className={panelContent.imgAlt}
      alt={panelContent.imgAlt}
      file={file}
    />
  )

  return (
    <div className={`content-panel ${industry} ${bgClass} ${sizeClass}`}>
      {order % 2 === 0 ? (
        <div className="container">
          <div className="text">
            <div className="bar" />
            <div className="title">{panelContent.title}</div>
            <div className="subtitle">{panelContent.subtitle}</div>
            <a
              href={panelContent.linkUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="link">{panelContent.linkText}</div>
            </a>
          </div>
          <div className="image">{theImage}</div>
        </div>
      ) : (
        <div className="container">
          <div className="image">{theImage}</div>
          <div className="text">
            <div className="bar" />
            <div className="title">{panelContent.title}</div>
            <div className="subtitle">{panelContent.subtitle}</div>
            <a
              href={panelContent.linkUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="link">{panelContent.linkText}</div>
            </a>
          </div>
        </div>
      )}
    </div>
  )
}

ContentPanel.propTypes = {
  industry: PropTypes.string,
  order: PropTypes.number,
  width: PropTypes.number.isRequired,
  file: PropTypes.shape().isRequired,
}

export default ContentPanel
