import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from '../components/Shared'
import Industry, { generateIndustryHeader } from '../components/Industry'
import { arrayToObject } from '../utils/image'

export const query = graphql`
  {
    arch1: file(
      relativeDirectory: { eq: "splash/industry/architecture" }
      name: { eq: "1" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 374) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    arch2: file(
      relativeDirectory: { eq: "splash/industry/architecture" }
      name: { eq: "2" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 272) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    arch3: file(
      relativeDirectory: { eq: "splash/industry/architecture" }
      name: { eq: "3" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 264) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    arch4: file(
      relativeDirectory: { eq: "splash/industry/architecture" }
      name: { eq: "4" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 236) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    callison: file(
      relativeDirectory: { eq: "logos/trusted/architecture" }
      name: { eq: "callison" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 176) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    ennead: file(
      relativeDirectory: { eq: "logos/trusted/architecture" }
      name: { eq: "ennead" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 111) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    hmc: file(
      relativeDirectory: { eq: "logos/trusted/architecture" }
      name: { eq: "hmc" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxHeight: 22, maxWidth: 206) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    marmonMok: file(
      relativeDirectory: { eq: "logos/trusted/architecture" }
      name: { eq: "marmon-mok" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 145) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    shop: file(
      relativeDirectory: { eq: "logos/trusted/architecture" }
      name: { eq: "shop" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 59) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    eng1: file(
      relativeDirectory: { eq: "splash/industry/engineering" }
      name: { eq: "1" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 292) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    eng2: file(
      relativeDirectory: { eq: "splash/industry/engineering" }
      name: { eq: "2" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 225) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    eng3: file(
      relativeDirectory: { eq: "splash/industry/engineering" }
      name: { eq: "3" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 164) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    eng4: file(
      relativeDirectory: { eq: "splash/industry/engineering" }
      name: { eq: "4" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 254) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    bilfinger: file(
      relativeDirectory: { eq: "logos/trusted/engineering" }
      name: { eq: "bilfinger" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 135) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    crb: file(
      relativeDirectory: { eq: "logos/trusted/engineering" }
      name: { eq: "crb" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 59) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    omicron: file(
      relativeDirectory: { eq: "logos/trusted/engineering" }
      name: { eq: "omicron" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 104) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    smw: file(
      relativeDirectory: { eq: "logos/trusted/engineering" }
      name: { eq: "smw" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 40) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    solagro: file(
      relativeDirectory: { eq: "logos/trusted/engineering" }
      name: { eq: "solagro" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 47) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    construction1: file(
      relativeDirectory: { eq: "splash/industry/construction" }
      name: { eq: "1" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 245) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    construction2: file(
      relativeDirectory: { eq: "splash/industry/construction" }
      name: { eq: "2" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 279) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    construction3: file(
      relativeDirectory: { eq: "splash/industry/construction" }
      name: { eq: "3" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 218) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    construction4: file(
      relativeDirectory: { eq: "splash/industry/construction" }
      name: { eq: "4" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 333) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    gly: file(
      relativeDirectory: { eq: "logos/trusted/construction" }
      name: { eq: "gly" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 46) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    gse: file(
      relativeDirectory: { eq: "logos/trusted/construction" }
      name: { eq: "gse" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 63) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    hagerman: file(
      relativeDirectory: { eq: "logos/trusted/construction" }
      name: { eq: "hagerman" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 79) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    mortenson: file(
      relativeDirectory: { eq: "logos/trusted/construction" }
      name: { eq: "mortenson" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 87) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    paric: file(
      relativeDirectory: { eq: "logos/trusted/construction" }
      name: { eq: "paric" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 98) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    edu1: file(
      relativeDirectory: { eq: "splash/industry/educators" }
      name: { eq: "1" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 215) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    edu2: file(
      relativeDirectory: { eq: "splash/industry/educators" }
      name: { eq: "2" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 209) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    edu3: file(
      relativeDirectory: { eq: "splash/industry/educators" }
      name: { eq: "3" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 101) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    stu1: file(
      relativeDirectory: { eq: "splash/industry/students" }
      name: { eq: "1" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 101) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    stu2: file(
      relativeDirectory: { eq: "splash/industry/students" }
      name: { eq: "2" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 180) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    stu3: file(
      relativeDirectory: { eq: "splash/industry/students" }
      name: { eq: "3" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    isu: file(
      relativeDirectory: { eq: "logos/trusted/educators" }
      name: { eq: "isu" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 260) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    kansasState: file(
      relativeDirectory: { eq: "logos/trusted/educators" }
      name: { eq: "kansas-state" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 113) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    lawrenceTech: file(
      relativeDirectory: { eq: "logos/trusted/educators" }
      name: { eq: "lawrence-tech" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 106) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    stThomas: file(
      relativeDirectory: { eq: "logos/trusted/educators" }
      name: { eq: "st-thomas" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 177) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    uva: file(
      relativeDirectory: { eq: "logos/trusted/educators" }
      name: { eq: "uva" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 50) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`

export default ({ data, pageContext: { industry } }) => {
  let testimonials = []
  let panels = []
  switch (industry) {
    case 'architecture':
      testimonials = [
        data.callison,
        data.ennead,
        data.hmc,
        data.marmonMok,
        data.shop,
      ]
      panels = [data.arch1, data.arch2, data.arch3, data.arch4]
      break
    case 'construction':
      testimonials = [
        data.gly,
        data.gse,
        data.hagerman,
        data.mortenson,
        data.paric,
      ]
      panels = [
        data.construction1,
        data.construction2,
        data.construction3,
        data.construction4,
      ]
      break
    case 'engineering':
      testimonials = [
        data.bilfinger,
        data.crb,
        data.omicron,
        data.smw,
        data.solagro,
      ]
      panels = [data.eng1, data.eng2, data.eng3, data.eng4]
      break
    case 'educators':
      testimonials = [
        data.isu,
        data.kansasState,
        data.lawrenceTech,
        data.stThomas,
        data.uva,
      ]
      panels = [data.edu1, data.edu2, data.edu3]
      break
    case 'students':
      testimonials = [
        data.isu,
        data.kansasState,
        data.lawrenceTech,
        data.stThomas,
        data.uva,
      ]
      panels = [data.stu1, data.stu2, data.stu3]
      break
  }

  return (
    <>
      <Helmet {...generateIndustryHeader(industry)} />
      <Industry
        industry={industry}
        testimonialImageFiles={arrayToObject(testimonials)}
        panelImageFiles={panels}
      />
    </>
  )
}
