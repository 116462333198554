import React from 'react'
import PropTypes from 'prop-types'
import { IrisCTALinkButton } from '../../Shared'
import { getSignupUrl } from '../../../utils/url'


const signupURL = getSignupUrl()
const StartTrial = ({ industry, width }) => {
  const sizeClass = width < 745 ? 'small' : width > 992 ? '' : 'medium'

  return (
    <div className="start-trial">
      {(() => {
        switch (industry) {
          case 'educators':
          case 'students':
            return (
              <div className={`container edu ${sizeClass}`}>
                <div className="text">
                  <div className="large">
                    Ready to speed up coordination &amp; design review?
                  </div>
                  <div>Over 60,000 AEC professionals use Prospect to make fast, accurate decisions.</div>
                </div>
                <IrisCTALinkButton
                      href={signupURL}
                      cta={'Try for Free'}
                    />
              </div>
            )
          default:
            return (
              <div className={`container ${sizeClass}`}>
                <div className="left-div">
                  <div className="text">
                    <div>Have a VR headset?</div>
                    <div>Try out IrisVR today</div>
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <IrisCTALinkButton
                      href={signupURL}
                      cta={'Start my free trial'}
                    />
                  </div>
                </div>
                <div className="divider-div" />
                <div className="right-div">
                  <div className="text">
                    <div>Have a specific request?</div>
                    <div>Contact our team</div>
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <IrisCTALinkButton
                      theme="blackOnBlack"
                      href="https://help.irisvr.com/hc/en-us/requests/new"
                      target="_blank"
                      rel="noopener noreferrer"
                      cta={'Talk to us'}
                    />
                  </div>
                </div>
              </div>
            )
        }
      })()}
    </div>
  )
}

StartTrial.propTypes = {
  industry: PropTypes.string,
  width: PropTypes.number.isRequired,
}

export default StartTrial
