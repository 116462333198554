import content from './content'
import { getWindowLocationOrigin } from '../../utils/window'
import { generateMetaTags } from '../../utils/helmet'

export default function(industry) {
  const { title, desc: description, imageUrl: image } = content[industry].meta

  const url = `${getWindowLocationOrigin()}/industry/${industry}`
  const imageWidth = 1200
  const imageHeight = 630

  return generateMetaTags({
    title,
    description,
    image,
    url,
    imageWidth,
    imageHeight,
  })
}
