import React from 'react'
import ContentPanel from './sections/ContentPanel'
import StartTrial from './sections/StartTrial'
import MainPanel from './sections/MainPanel'
import EduPanel from './sections/EduPanel'
import generateIndustryHeader from './helmetProps'
import './IndustrySplash.global.scss'
import { Layout, LogoBanner } from '../Shared'

class Industry extends React.Component {
  render() {
    const {
      width,
      testimonialImageFiles,
      panelImageFiles,
      industry,
    } = this.props
    const sizeClass = width < 770 ? 'small' : width > 992 ? '' : 'medium'

    const isEduOrStudents = industry === 'educators' || industry === 'students'

    return (
      <div className={`industry-splash ${sizeClass}`}>
        <MainPanel industry={industry} width={width} />
        {/* <LogoBanner
          images={testimonialImageFiles}
          industry={industry}
          type="trusted"
          largeWidth={sizeClass !== 'small'}
        /> */}
        {isEduOrStudents ? (
          <EduPanel
            images={panelImageFiles}
            industry={industry}
            width={width}
          />
        ) : (
          <div>
            {panelImageFiles.map((file, i) => (
              <ContentPanel
                key={i}
                file={file}
                width={width}
                order={i + 1}
                industry={industry}
              />
            ))}
          </div>
        )}
        <StartTrial industry={industry} width={width} />
      </div>
    )
  }
}

export default ({ industry, ...props }) => (
  <Layout>
    <Industry industry={industry} {...props} />
  </Layout>
)

export { generateIndustryHeader }
