import React from 'react'
import PropTypes from 'prop-types'
import content from './content'
import { IrisCTALinkButton } from '../../Shared'
import { getSignupUrl } from '../../../utils/url'

const signupURL = getSignupUrl()

const MainPanel = ({ industry, width }) => {
  const panelContent = content[industry].main
  const sizeClass = width < 745 ? 'small' : width > 992 ? '' : 'medium'

  /**
   * Architecture is the only industry whose content
   * in this main panel is right aligned
   **/
  if (industry === 'architecture') {
    return (
      <div className={`main ${industry} ${sizeClass}`}>
        <div className="mobile-banner" />
        <div className="content">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
              width: 300,
              ...(sizeClass === 'small' ? { alignSelf: 'center' } : {}),
            }}
          >
            <div style={{ alignContent: 'flex-start' }}>
              <div
                className="title"
                style={{ paddingBottom: sizeClass === 'small' ? 25 : 15 }}
              >
                {panelContent.title}
              </div>
              <div
                className="subtitle"
                style={{ paddingBottom: sizeClass === 'small' ? 25 : 15 }}
              >
                {panelContent.subtitle}
              </div>
              <div
                className="desc"
                style={{ paddingBottom: sizeClass === 'small' ? 25 : 15 }}
              >
                {panelContent.desc}
              </div>
              <IrisCTALinkButton
                href={signupURL}
                cta={'Explore Our Products'}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={`main ${industry} ${sizeClass}`}>
      <div className="mobile-banner" />
      <div className="content">
        <div className="title">{panelContent.title}</div>
        <div className="subtitle">{panelContent.subtitle}</div>
        <div className="desc">{panelContent.desc}</div>
        <div
          style={{ ...(sizeClass === 'small' ? { alignSelf: 'center' } : {}) }}
        >
          <IrisCTALinkButton
            href={signupURL}
            cta={
              industry !== 'educators' && industry !== 'students'
                ? 'Explore Our Products'
                : 'Explore IrisVR'
            }
          />
        </div>
      </div>
    </div>
  )
}

MainPanel.propTypes = {
  industry: PropTypes.string,
  width: PropTypes.number.isRequired,
}

export default MainPanel
