import React from 'react'
import PropTypes from 'prop-types'
import content from './content'
import { IrisImg, IrisCTALinkButton } from '../../Shared'

const EduPanel = ({ images, width, industry }) => {
  const panelContent = content[industry]
  const sizeClass = width < 745 ? 'small' : width > 992 ? '' : 'medium'

  return (
    <div className={`content-panel edu ${sizeClass} ${industry}`}>
      <div className="icons">
        {images.map((file, i) => (
          <div key={i} className="container">
            <div className="image">
              <IrisImg alt={panelContent[i + 1].imgAlt} file={file} />
            </div>
            <div className="text">
              <div className="bar" />
              <div className="title title-mini">
                {panelContent[i + 1].title}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="link">
        <div
          style={{ ...(sizeClass !== 'small' ? { paddingBottom: 40 } : {}) }}
        >
          <IrisCTALinkButton
            href={panelContent.main.linkUrl}
            target="_blank"
            rel="noopener noreferrer"
            cta={panelContent.main.linkText}
          />
        </div>
      </div>
    </div>
  )
}

EduPanel.propTypes = {
  industry: PropTypes.string,
  width: PropTypes.number.isRequired,
}

export default EduPanel
